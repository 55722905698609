import react, {useEffect} from 'react';


import Marquee from '../../components/00Global/01Marquee';
import Navbar from '../../components/00Global/02NavBar';
import Footer from '../../components/00Global/03Footer';
import DocumentsList from '../../components/08Documents/DocumentsList';



const Documents = () => {
    useEffect(()=> {
        document.title = 'Documents · Club Olesa Patí';
      })
    return (
        <div className='min-h-screen flex flex-col justify-between'>
            <div>
                <Marquee />
                <Navbar active="competicio" />
            </div>
            <DocumentsList />
            <Footer />
        </div>
    )
}
export default Documents;