
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faTwitter, faTelegram,faFacebook } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { useEffect } from 'react';
const Footer = () => {

    useEffect(()=>{

    })
    return (<>
{/*     <div className=' bottom-[-50px] w-full hidden md:block ' id="footer"> */}
<div className=' ' id="footer">
         <div className="custom-shape-divider-bottom-1696325800 hidden md:block">
            <svg data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
            </svg>
        </div>
        <div className='bg-green-600  '>
            <div className="max-w-7xl mx-auto  p-3 lg:px-0 py-10 text-white">
                <div className='w-ful md:w-1/2 lg:w-1/3 float-left  text-left  p-2 lg:p-0' >
                    <h3 className='font-bold'>Club Olesa Patí</h3>
                    <p>Carrer de l'Urgell, s/n</p>
                    <p>08640 Olesa de Montserrat, Barcelona</p>
                    <p>635 67 36 94</p></div>
                <div className='hidden md:block  md:w-1/2 lg:w-1/3 float-left p-3  ' > <div >
                    <ul className="cursor-pointer text-sm sm:text-base lg:columns-2">
                    <li className="hover:text-slate-900 hover:font-semibold font-semibold float-left px-6 hover:translate-y-1 transition-all "><Link to="/">INICI</Link></li>
                        <li className="hover:text-slate-900 hover:font-semibold font-semibold float-left px-6 hover:translate-y-1 transition-all"><Link to="/competicio">COMPETICIÓ</Link></li>
                        <li className="hover:text-slate-900 hover:font-semibold font-semibold float-left px-6 hover:translate-y-1 transition-all"><Link to="/equips">EQUIPS</Link></li>
                        <li className="hover:text-slate-900 hover:font-semibold font-semibold float-left px-6 hover:translate-y-1 transition-all"><Link to="/noticies">NOTÍCIES</Link></li>
                        <li className="hover:text-slate-900 hover:font-semibold font-semibold float-left px-6 hover:translate-y-1 transition-all"><Link to="/documents">DOCUMENTS</Link></li>
                        <li className="hidden hover:text-slate-900 hover:font-semibold font-semibold float-left px-6 hover:translate-y-1 transition-all"><Link to="/multimedia">MULTIMEDIA</Link></li>
                        <li className="hover:text-slate-900 hover:font-semibold font-semibold float-left px-6 hover:translate-y-1 transition-all"><Link to="/mapa">MAPA</Link></li>
                      
                 
                    </ul>
                </div></div>
                <div className='hidden lg:block md:w-1/3 float-left p-3 text-center text-2xl' >
                 <a href='http://twitter.com/clubolesapati' target="_blank"><FontAwesomeIcon icon={faTwitter} className=" p-5" /></a>
                <a href='http://instagram.com/clubolesapati' target="_blank"> <FontAwesomeIcon icon={faInstagram} className=" p-5" /></a>
                <a href='https://www.facebook.com/profile.php?id=100064129746234' target="_blank"><FontAwesomeIcon icon={faFacebook} className=" p-5" /></a>
                <a href='https://t.me/clubolesapati' target="_blank"><FontAwesomeIcon icon={faTelegram} className=" p-5" /></a>
                <FontAwesomeIcon icon={faEnvelope} className=" p-5" /></div>
                <div className='clear-both'></div>
                &nbsp;
            </div>
        </div></div></>
    )

}
export default Footer;