import react, {useEffect} from 'react';


import Marquee from '../../components/00Global/01Marquee';
import Navbar from '../../components/00Global/02NavBar';
import Footer from '../../components/00Global/03Footer';
import LeaguesList from '../../components/02Leagues/01LeaguesList'


const Leagues = () => {
    useEffect(()=> {
        document.title = 'Competició · Club Olesa Patí';
      })
    return (
        <div className='absolute z-50'>
            <Marquee />
            <Navbar active="competicio" />
            <LeaguesList />
            
            <Footer /> 
        </div>
    )
}
export default Leagues;