import { useEffect } from 'react';
import Marquee from '../../components/00Global/01Marquee';
import Navbar from '../../components/00Global/02NavBar';
import Footer from '../../components/00Global/03Footer';
const Map = () => {
    useEffect(()=> {
        document.title = 'Mapa · Club Olesa Patí';
      })
    return (
        <div className='min-h-screen flex flex-col justify-between'>
            <div>
                <Marquee />
                <Navbar active="competicio" />
            </div>
            <div>
                {/* <h1>Pavelló Municipal Salvador Boada</h1> */}
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2985.6836177962923!2d1.8887102154309874!3d41.55444707924916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a48b72b5ef3b1d%3A0x2d50b6edbfc4d0d3!2sPavell%C3%B3%20Municipal%20Salvador%20Boada!5e0!3m2!1ses!2ses!4v1647789198038!5m2!1ses!2ses" width="100%" height="600" allowfullscreen="" loading="lazy"></iframe>
            </div>
            <Footer />
        </div>)
}
export default Map;