import { useState, useEffect } from 'react';

import Marquee from '../../components/00Global/01Marquee';
import Navbar from '../../components/00Global/02NavBar';
import Footer from '../../components/00Global/03Footer';
import { Link } from 'react-router-dom';
const Teams = () => {
    useEffect(() => {
        document.title = 'Equips · Club Olesa Patí';
    })
    const [teams, setTeams] = useState([]);

    const fetchUserData = () => {
        fetch("https://clubolesapati.cat/API/apiEquips.php?top=3&headline=1")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setTeams(data);

            })
    }

    useEffect(() => {
        fetchUserData();
    }, []);
    return (<>
        <div className='min-h-screen flex flex-col justify-between'>
            <div>
                <Marquee />
                <Navbar />
            </div>
            <div className="max-w-7xl mx-auto  text-green-900 text-lg mt-0 p-4 lg:p-0 ">
                <h3 className="w-full text-medium md:text-4xl text-green-700 lg:pt-12 lg:pb-6  my-auto pl-4 "> <strong>Equips</strong></h3>
                {teams.map((n, index) => (
                    //   <Link className='cursor-pointer' to={`/equips/${n.teamId}-${n.teamName}`}>
                    <div className='w-full  lg:w-1/3 lg:float-left p-3 hover:p-3 hover:-translate-y-2 transition-all duration-1000 ' key={index}>
                        <div className='rounded-lg bg-white border-2 hover:shadow-2xl'>

                            <img alt={n.title} className='rounded-t-lg h-100 aspect-[5/3]' src={'https://clubolesapati.cat/content/images/teams/' + n.image} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "https://www.pngmart.com/files/22/White-Background-PNG-Photo.png";
                            }} />
                            <div className='w-1/2'></div>

                            <div className="text-center font-bold flex justify-center capitalize">
                                <div className="w-full   bg-green-600 p-2 text-slate-100 ">{n.teamName.toLowerCase()}
                                    {/* <Link className='cursor-pointer' to={`/equips/${n.teamId}-${n.teamName}`}>{n.teamName.toLowerCase()}</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    // </Link>

                ))}

            </div>
            <div className='clear-both'></div>
            <Footer />
        </div>
    </>
    )
}

export default Teams;