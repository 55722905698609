import react, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';

const LeaguesList = () => {
  const [leagues, setLeagues] = useState([]);
  const idSeason = useRef(0);
  const idDivision = useRef(0);
  idSeason.current = 0;
  idDivision.current = 0;

  const divisionName = useRef(0);
  divisionName.current = 0;
  const fetchLeaguesData = () => {
    fetch("https://clubolesapati.cat/API/apiLligues.php")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setLeagues(data);

      })
  }
  useEffect(() => {
    fetchLeaguesData();

  }, [setLeagues]);

  return (
    <>
      <div className='w-full'>
        <div className="max-w-7xl mx-auto w-full   text-lg mt-0 p-6  lg:p-2 ">
          <h3 className="w-full text-xl md:text-4xl text-green-700 lg:pt-12 lg:pb-6 text-left my-auto"><strong>Competició
          </strong></h3>
          {console.log(leagues)}
          {leagues.map((league) => {

            if (idSeason.current !== league.seasonName && idDivision.current !== league.divisionName) {
              idSeason.current = league.seasonName;
              idDivision.current = league.divisionName;
              return (
                <div className='clear-both' key={league.idLeague}> &nbsp; <div className=' my-1' >
                  <h1 className='lg:pt-15 font-bold text-green-600 text-xl '>Temporada {league.seasonDateStart.substring(0, 4)}-{league.seasonDateEnd.substring(2, 4)}</h1></div>
                  <div className=' clear-both text-green-700 lg:py-5'>Categoría {league.divisionName}</div>
                  <div>
                    <div className='w-screen  md:w-1/2  lg:w-1/3 md:float-left my-5'>
                      <Link className={`${league.isActive > 0 ? 'text-green-500 hover:bg-green-500 ' : 'text-slate-500 hover:bg-slate-500 '}` + ' rounded-xl bg-gray-50 m-3 p-1 lg:p-5 shadow-md p-3 text-xs md:text-sm hover:text-base font-bold hover:text-white transition-all duration-500'} to={`../competicio/lliga/${league.idLeague}-${league.leagueName}`}>{league.leagueName} {league.groupName}</Link>
                    </div>
                  </div>
                </div>
              )

            }

            else if (idDivision.current !== league.divisionName) {
              idSeason.current = league.seasonName;
              idDivision.current = league.divisionName;
              return (<>

                <div className=' clear-both text-green-700 lg:py-5' key={league.idLeague}>Categoría {league.divisionName}</div>
                <div>
                  <div className='w-fit  md:w-1/2  lg:w-1/3 md:float-left my-3'>
                    <Link className={`${league.isActive > 0 ? 'text-green-500 hover:bg-green-500  ' : 'text-slate-500 hover:bg-slate-500 '}` + ' rounded-xl bg-gray-50 m-3 p-1 lg:p-5 shadow-md p-3 text-xs md:text-sm hover:text-base font-bold  hover:text-white transition-all duration-500'} to={`../competicio/lliga/${league.idLeague}-${league.leagueName}`}>{league.leagueName} {league.groupName}</Link>
                  </div>
                </div>
              </>
              )

            } else {
              idDivision.current = league.divisionName;
              idDivision.current = league.divisionName;
              return (<div key={league.idLeague}>
                <div className='w-screen  md:w-1/2 lg:w-1/3 md:float-left my-5' key={league.idLeague}>
                  <Link className={`${league.isActive > 0 ? 'text-green-500 hover:bg-green-500 ' : 'text-slate-500 hover:bg-slate-500 '}` + '  rounded-xl bg-gray-50 m-3  p-1 lg:p-5 shadow-md p-3 text-xs md:text-sm hover:text-base  font-bold hover:text-white transition-all duration-500'} to={`../competicio/lliga/${league.idLeague}-${league.leagueName}`}>{league.leagueName} {league.groupName} </Link>
                </div>
              </div>)

            }



          })}
          <div className='clear-both'>&nbsp;</div>
          <div className='md:min-h-[250px]'>&nbsp;</div>
        </div>
      </div >
    </>
  )

}
export default LeaguesList


