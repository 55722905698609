import React, { useState, useEffect } from "react";
import Chronicle from '../../01Home/04Chronicle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import { faClock, faCalendar, faCar } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const NextMatchesLastResults = () => {


    const [nextMatches, setNextMatches] = useState([]);
    const [lastResults, setLastResults] = useState([]);
    const fetchMatchesData = () => {
        fetch("https://clubolesapati.cat/API/apiPropersPartits.php")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setNextMatches(data);

            })
    }
    const fetchResultsData = () => {
        fetch("https://clubolesapati.cat/API/apiResultats.php")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setLastResults(data);

            })
    }
    useEffect(() => {
        fetchMatchesData();
        fetchResultsData()

    }, []);

    return (<div className="snap-center  w-full h-1/4 lg:h-auto  bg-[url('https://clubolesapati.cat/images/dynamic/newsImages/boada2.jpg')]  bg-cover bg-center flex justify-center items-center  pt-0 pb-0">
        <div className="max-w-7xl mx-auto w-full  text-white text-lg mt-6 p-2 ">
            <h3 className="w-full text-4xl text-slate-100 pt-0 md:pt-8 pb-12 text-center my-auto">Propers <strong>partits </strong> / Darrers <strong>resultats</strong></h3>
            <div className="w-full md:w-7/12 flex  float-left">
                <div className="w-full md:w-11/12  float-left">
                    {nextMatches?.map((match, index) => {
                        var minutes = match.travelTime / 60;
                        var hours = minutes / 60;
                        if (hours >= 1) {
                            hours = hours.toString();
                            var tt = "(" + hours.substr(0, 3) + "  h)";
                        } else {
                            var tt = "(" + Math.round(minutes) + " m) ";
                        }
                        if (match.distance == 0) {
                            tt = "";
                            d = "";
                        } else {
                            var d = match.distance;
                            d = "" + match.distance + " km ";
                        }
                        if (match.meteo) {
                            var m = match.meteo.split("|");
                            var temp = m[0];
                            var meteoIcon = m[1];

                        }
                        if (index === 0) {
                            return (<div className=" w-full  bg-white bg-opacity-80  border-2 border-gray-200 p-8 transition-all" key={match.matchId} >
                                <div className="w-full float-left text-slate-900  place-content-center ">
                                    <div className="text-center">
                                        <img alt={'Escut de ' + match.local} className='w-6 md:w-14 inline mr-3' src={match.localImage} />
                                        <a target="_blank" rel="noreferrer" href={'https://www.google.com/maps?q=' + match.complexAddress}>
                                            <span className="text-sm md:text-xl font-bold inline mr-3">{match.local}</span>
                                        </a>
                                        <span className="inline">vs</span>
                                        <span className=" text-sm md:text-xl ml-2 font-bold inline">{match.visitor}</span>
                                        <img alt={'Escut de ' + match.visitor} className='w-6 md:w-14 inline ml-2' src={match.visitorImage} />

                                    </div>
                                </div>
                                <div className="w-full  text-slate-900 mt-12 text-base text-center ">
                                    <FontAwesomeIcon icon={faClock} className=" mr-3" />{match.matchDate.substr(0, 5)} {match.matchHour}  | {match.distance != 0 ? <span className="text-slate-900">{match.distance} km </span>  : null}<span className="text-slate-900">{tt}</span>  <img className="inline w-[24px]" src={meteoIcon} /> {temp}<br /></div>
                                <div className="clear-both "></div>
                            </div>)
                        } else {
                            return (<div className=" w-full mt-1 bg-white bg-opacity-60 border-2 border-gray-200  hover:bg-opacity-80 transition-all" key={match.matchId}>
                                <div className="w-7/12 lg:w-9/12 float-left p-6 text-slate-900   ">
                                    <div>
                                        <img alt={'Escut de ' + match.local} className='w-4 md:w-6 inline mr-1' src={match.localImage} />
                                        <a target="_blank" rel="noreferrer" href={'https://www.google.com/maps?q=' + match.complexAddress}>
                                            <span className="hidden text-sm lg:text-base  font-bold lg:inline mr-1">{match.local}</span>
                                            <span className="text-sm lg:text-base  font-bold inline lg:hidden mr-1">{match.truncatedLocal}</span>
                                        </a><br />
                                        <img alt={'Escut de ' + match.visitor} className='w-4 md:w-6 inline' src={match.visitorImage} />
                                        <span className="hidden lg:text-base ml-1 font-bold lg:inline ">{match.visitor}</span>
                                        <span className="text-sm lg:hidden ml-1 font-bold inline ">{match.truncatedVisitor}</span>

                                    </div>
                                </div>
                                <div className="w-5/12 lg:w-3/12 float-left text-slate-900 p-6 text-sm  md:text-base text-right  ">
                                    <FontAwesomeIcon icon={faClock} className=" mr-1 w-[12px]" /> {match.matchDate.substr(0, 5)}  {match.matchHour}  <br />

                                    {d != "" ? <><span className="text-slate-900 font-bold text-sm md:text-base ">  {d} </span><span className="text-slate-900 md:text-base ">{tt}</span><br /></> : ""}
                                    <img className="inline w-[16px]" src={meteoIcon} /> {temp}<br />

                                </div>
                                <div className="clear-both "></div>
                            </div>)
                        }
                    }
                    )}
                    <div className="w-full bg-slate-600 p-8 text-center bg-opacity-80 mt-1"><FontAwesomeIcon icon={faCalendar} />  &nbsp;<Link to="/calendari">Veure calendari</Link> </div>
                </div>
                <div className="w-0 md:w-1/12 ">&nbsp;</div>
            </div>
            <div className="w-full md:w-5/12 float-left  ">
                {lastResults?.map((match, index) => {
                    return (<div className=" w-full  bg-white mb-1  bg-opacity-60 border-2 border-gray-200  hover:bg-opacity-80 transition-all" key={match.matchId}>
                        <div className="w-full float-left p-2 text-slate-900  text-center   place-content-center">
                            <div className="text-center">
                                <img alt={'Escut de ' + match.local} className='w-4 md:w-6 inline mr-1' src={match.localImage} />
                                <span className=" text-sm lg:text-base font-bold inline mr-1">{match.local}</span>
                                <span className="inline">-</span>
                                <span className="ml-1 font-bold inline text-sm lg:text-base">{match.visitor}</span>
                                <img className='w-4 md:w-6 inline ml-1' src={match.visitorImage} alt={'Escut de ' + match.visitor} />
                            </div>
                            <div className="text-center font-bold flex justify-center mt-2"><div className="w-20  bg-slate-400 rounded-xl p-2">{match.localResult} - {match.visitorResult}</div></div>
                        </div>
                        {/*  <div className="w-3/12 float-left text-slate-900 p-6 text-sm text-right  ">
                        <FontAwesomeIcon icon={faClock} className=" mr-3" /> {match.matchDate} {match.matchHour}</div> */}
                        <div className="clear-both "> </div>
                    </div>)
                }




                )}
            </div>
            <div className="clear-both">&nbsp;</div>
            <Chronicle />
        </div>

    </div>

    )
}

export default NextMatchesLastResults;