import React, { useState, useEffect } from "react";
import Marquee from "../../components/00Global/01Marquee";
import Navbar from "../../components/00Global/02NavBar";
import { useParams } from 'react-router-dom'
import Footer from "../../components/00Global/03Footer";
const NewsDetail = () => {
    let { id } = useParams();
    let idTeam = id.split("-");
    id = idTeam[0];

    useEffect(()=> {
        document.title = idTeam[1]+' · Club Olesa Patí';
      })
    const [news, setNews] = useState([]);
    const fetchNewsData = () => {

        fetch("https://clubolesapati.cat/API/apiNoticies.php?top=100&headline=0&id=" + id)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setNews(data);

            })
    }
    useEffect(() => {
        fetchNewsData();
    }, []);


    console.log(news);

    return (<>
        <div className='min-h-screen flex flex-col justify-between'>
            <div>
                <Marquee />
                <Navbar />
            </div>
            {news?.map((n, index) => {
                let UpdateDate = n.UpdateDate.split(" ");
                let d = UpdateDate[0].split("-");
                let date = d[2] + "-" + d[1] + "-" + d[0];
                let hour = UpdateDate[1].substring(0, 5);
                n.UpdateDate = date ;
                return (<div className="max-w-7xl mx-auto  text-green-900 text-base lg:text-lg mt-0 bg-white " key={n.id}>
                    <h1 className="w-full  text-base lg:text-3xl text-green-700 mt-2 lg:mt-12 text-left my-auto pl-3 xl:pl-0  "><strong>{n.title}</strong></h1>
                    <h2 className="w-3/4 float-left text-base lg:text-medium text-slate-400  text-left my-auto   pl-3 xl:pl-0 "><strong>{n.subtitle}</strong></h2>
                    <div className="w-1/4 float-left text-right  pl-3 xl:pl-0 text-base lg:text-medium">{n.UpdateDate}</div>
                    <img className="clear-both pb-2 w-full" src={"https://clubolesapati.cat//images/dynamic/newsImages/" + n.pathImage} />
                    <div className='text-base text-slate-800 p-3 xl:p-0' dangerouslySetInnerHTML={{ __html: n.content }}></div>
                </div>)
            })}
            <div className='clear-both'></div>
            <Footer />
        </div >
    </>)
}
export default NewsDetail;