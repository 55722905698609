import react, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';

const DocumentsList = () => {
    const [documents, setDocuments] = useState([]);
    const category = useRef(0);

    category.categoryName = "";


    const divisionName = useRef(0);
    divisionName.current = 0;
    const fetchDocumentsData = () => {
        fetch("https://clubolesapati.cat/API/apiDocuments.php")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setDocuments(data);

            })
    }
    useEffect(() => {
        fetchDocumentsData();

    }, [setDocuments]);

    return (
        <>
            <div className='w-full'>
                <div className="max-w-7xl mx-auto  text-green-900 text-lg mt-0 p-4 lg:p-0 ">
                    <h3 className="w-full text-medium md:text-4xl text-green-700 lg:pt-12   my-auto md:pl-4 mb-4"><strong>Documents
                    </strong>
                    </h3>
                    {documents.map((document) => {

                        if (category.categoryName !== document.category) {
                            category.categoryName = document.category

                            return (
                                <div className='clear-both' key={document.category}>
                                    <div className=' my-1' >
                                        <h1 className='lg:pt-6 font-bold text-green-600 lg:text-2xl md:px-4 '> {document.category}</h1>
                                    </div>

                                    <div>
                                        <div className='w-screen  md:w-1/2  lg:w-1/3 md:float-left my-5'>
                                            <Link  target={"_blank"} rel="noopener noreferrer"  className={'uppercase  hover:bg-green-500 text-green-500  rounded-xl  bg-gray-50 m-3 p-1 lg:p-5 shadow-md p-3 text-xs md:text-sm hover:text-base   font-bold  hover:text-white transition-all duration-500'} to={`https://clubolesapati.cat/content/documentacio/${document.filepath}`}>{document.fileName}</Link>
                                        </div>
                                    </div>
                                </div>
                            )


                        } else {

                            return (<div key={document.fileName}>
                                <div className='w-screen  md:w-1/2 lg:w-1/3 md:float-left my-5' >
                                    <Link target={"_blank"} rel="noopener noreferrer"  className={'uppercase  hover:bg-green-500 text-green-500 rounded-xl bg-gray-50 m-3  p-1 lg:p-5 shadow-md p-3 text-xs md:text-sm hover:text-base  font-bold hover:text-white transition-all duration-500'} to={`https://clubolesapati.cat/content/documentacio/${document.filepath}`}>{document.fileName} </Link>
                                </div>
                            </div>)

                        }
                        category.categoryName = document.category


                    })}
                    <div className='clear-both'>&nbsp;</div>
                    <div className='md:min-h-[250px]'>&nbsp;</div>
                </div>
            </div >
        </>
    )

}
export default DocumentsList


