import { React, useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faBars } from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink, useLocation } from "react-router-dom";
const Navbar = (props) => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(false);
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
    const [searchCollapse, setSearchCollapse] = useState(false);
    const [searchItem, setSearchItem] = useState("");
    const [searchData, setSearchData] = useState([]);
    const handleSearchForm = (e) => { setSearchItem(e); console.log(searchItem) }
    const handleSearchClick = () => {
        setSearchCollapse(!searchCollapse);

    }

    const fetchSearchData = () => {
        if (searchItem.length > 3) {
            fetch("https://clubolesapati.cat/API/apiSearch.php?search=" + searchItem)
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    setSearchData(data);
                    console.log(searchData)

                })
        }
    }
    useEffect(() => {
        fetchSearchData();

    }, [searchItem]);
    return (<>

        <div className="w-screen bg-green-900 p-4 bg-opacity-50 lg:bg-opacity-50">
            <div className="max-w-7xl mx-auto text-white">
                <div className={`${isNavCollapsed ? 'hidden' : 'block'}` + " w-24 float-left"} >
                    <img className="w-16  lg:w-24" src="https://clubolesapati.cat/content/images/logos/logo_main_244.png" alt="Club Olesa Patí"></img>
                </div>
                {/*   <div className="hidden > */}
                <div className={`${isNavCollapsed ? 'block' : 'hidden'}` + " lg:block float-left lg:float-right pt-8 z-0  transition-all"} >
                    <ul className="cursor-pointer text-sm">
                        <li className="hover:text-green-500 text-xl lg:text-sm  hover:font-semibold font-semibold lg:float-left px-2 xl:px-6 py-3 lg:py-0 lg:hover:translate-y-1 transition-all"><NavLink style={({ isActive }) => ({
                            color: isActive ? '#24aa43' : '#fff',
                            background: isActive ? '#fff' : 'transparent',
                            padding: isActive ? 15 : 5,
                            marginTop: isActive ? 50 : 50,
                            borderRadius: 20,
                        })} to="/">INICI</NavLink></li>
                        <li className="hover:text-green-500 text-xl lg:text-sm  hover:font-semibold font-semibold lg:float-left px-2 xl:px-6 py-3 lg:py-0 lg:hover:translate-y-1 transition-all">
                            <NavLink style={({ isActive }) => ({

                                color: isActive ? '#24aa43' : '#fff',
                                background: isActive ? '#fff' : 'transparent',
                                padding: isActive ? 10 : 5,
                                borderRadius: 20
                            })} to="/competicio">COMPETICIÓ</NavLink></li>
                        <li className="hover:text-green-500 text-xl lg:text-sm hover:font-semibold font-semibold lg:float-left  px-2 xl:px-6 py-3 lg:py-0 lg:hover:translate-y-1 transition-all"><NavLink to="/equips" style={({ isActive }) => ({
                            color: isActive ? '#24aa43' : '#fff',
                            background: isActive ? '#fff' : 'transparent',
                            padding: isActive ? 10 : 5,
                            borderRadius: 20
                        })}>EQUIPS</NavLink></li>
                        <li className="hover:text-green-500 text-xl lg:text-sm hover:font-semibold font-semibold lg:float-left  px-2 xl:px-6 py-3 lg:py-0 lg:hover:translate-y-1 transition-all"><NavLink to="/noticies" style={({ isActive }) => ({
                            color: isActive ? '#24aa43' : '#fff',
                            background: isActive ? '#fff' : 'transparent',
                            padding: isActive ? 10 : 5,
                            borderRadius: 20
                        })}>NOTÍCIES</NavLink></li>
                        <li className="hover:text-green-500 text-xl lg:text-sm  hover:font-semibold font-semibold lg:float-left px-2 xl:px-6 py-3 lg:py-0 lg:hover:translate-y-1 transition-all"><NavLink to="/documents" style={({ isActive }) => ({
                            color: isActive ? '#24aa43' : '#fff',
                            background: isActive ? '#fff' : 'transparent',
                            padding: isActive ? 10 : 5,
                            borderRadius: 20
                        })}>DOCUMENTS</NavLink></li>
                        <li className="hover:text-green-500 text-xl lg:text-sm  hover:font-semibold font-semibold lg:float-left px-2 xl:px-6 py-3 lg:py-0 lg:hover:translate-y-1 transition-all"><NavLink to="/calendari" style={({ isActive }) => ({
                            color: isActive ? '#24aa43' : '#fff',
                            background: isActive ? '#fff' : 'transparent',
                            padding: isActive ? 10 : 5,
                            borderRadius: 20
                        })}>CALENDARI</NavLink></li>
                        <li className=" hover:text-green-500 text-xl lg:text-sm hover:font-semibold font-semibold lg:float-left  px-2 xl:px-6 py-3 lg:py-0 lg:hover:translate-y-1 transition-all"><NavLink to="/multimedia" style={({ isActive }) => ({
                            color: isActive ? '#24aa43' : '#fff',
                            background: isActive ? '#fff' : 'transparent',
                            padding: isActive ? 10 : 5,
                            borderRadius: 20
                        })}>MULTIMEDIA</NavLink></li>
                        <li className="hover:text-green-500 text-xl lg:text-sm hover:font-semibold font-semibold lg:float-left  px-2 xl:px-6 py-3 lg:py-0  lg:hover:translate-y-1 transition-all"><NavLink to="/mapa" style={({ isActive }) => ({
                            color: isActive ? '#24aa43' : '#fff',
                            background: isActive ? '#fff' : 'transparent',
                            padding: isActive ? 10 : 5,
                            borderRadius: 20
                        })}>MAPA</NavLink></li>
                        <li className="text-xl lg:text-sm hover:font-semibold font-semibold lg:float-left  px-2 lg:px-6 py-2 lg:py-0  hover:translate-y-0 transition-all" >
                            <FontAwesomeIcon icon={faSearch} onClick={handleSearchClick} />
                            <input id="searchInput" onInput={e => handleSearchForm(e.target.value)} className={`${searchCollapse ? 'inline' : 'hidden'}` + ' rounded-xl p-3 ml-3 h-5 transition-all text-green-500 w-20'} type="text" ></input></li>
                    </ul>
                </div>
                {/* <div className="lg:hidden float-right p-2 mx-5 text-top text-2xl font-bold rounded-xl bg-green-700 "> */}
                <div className="lg:hidden float-right p-2 mx-5 text-top text-2xl font-bold">
                    <button onClick={handleNavCollapse}>
                        <FontAwesomeIcon icon={faBars} className=" p-1" /></button></div>
                <div className="clear-both"></div>
                <div className={`${searchItem.length > 3 || searchData.length > 70 ? 'block  ' : 'hidden  '}` + "bg-white text-green-800 rounded-xl p-5 mt-10 shadow-xl opacity-80 "}>

                    <div className={`${searchData.news ? 'block  ' : 'hidden  '}` + "bg-slate-500 p-5 rounded-xl text-white"} >
                        <h3 className="font-bold text-lg underline"> {searchData.news?.length}  resultats a notícies</h3>
                        {searchData.news?.map((search) => {
                            return (<div id={search.id} ><Link to={`/noticies/detall/${search.id}-${encodeURIComponent(search.title)}`}>&bull; {search.title} - {search.updateDate} </Link></div>)
                        })
                        }
                    </div>
                    <div className={`${searchData.matches ? 'block  ' : 'hidden  '}` + "bg-slate-500 p-5 rounded-xl text-white mt-3"} >
                        <h3 className={`${searchData.matches ? 'block  ' : 'hidden  '}` + "font-bold text-lg underline"}>{searchData.matches?.length} resultats a partits</h3>
                        {searchData.matches?.map((search) => {
                            return (<div  >&bull; {search.matchName} - {search.matchDate}</div>)
                        })
                        }
                    </div>
                    <div className={`${searchData.multimedia ? 'block  ' : 'hidden  '}` + "bg-slate-500 p-5 rounded-xl text-white mt-3"} >
                        <h3 className={`${searchData.multimedia ? 'block  ' : 'hidden  '}` + "font-bold text-lg underline"}>{searchData.multimedia?.length} resultats a multimedia</h3>
                        {searchData.multimedia?.map((search) => {
                            return (<div  >&bull; {search.multimediaName} - {search.multimediaDate}</div>)
                        })
                        }
                    </div>
                </div>
            </div>
        </div ></>)
}
export default Navbar; 