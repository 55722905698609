import Marquee from "../../components/00Global/01Marquee";
import Navbar from "../../components/00Global/02NavBar";
import Footer from "../../components/00Global/03Footer";
import { Link, useParams } from "react-router-dom";

import React, { useEffect, useState } from "react";
const Multimedia = () => {


    let { id } = useParams();
    if (id) {
        let idTeam = id.split("-");
        id = idTeam[0];
        console.log(id);
    }

    const [multimedia, setMultimedia] = useState([]);
    useEffect(() => {

        document.title = 'Multimedia  · Club Olesa Patí';
    }, [])


    const fetchMultimediaData = () => {
        fetch("https://clubolesapati.cat/API/apiMultimedia.php")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setMultimedia(data);

            })
    }

    useEffect(() => {
        fetchMultimediaData();
        
    }, []);

    return (<>
        <div className='min-h-screen flex flex-col justify-between '>
            <div>
                <Marquee />
                <Navbar />
            </div>
            <div className="max-w-7xl mx-auto  text-green-900 text-lg mt-0 p-4 lg:p-0 " id="video">
                <h3 className="w-full text-medium md:text-4xl text-green-700 lg:pt-12   my-auto lg:pl-4 mb-4 "> <strong>Multimedia</strong></h3>
                <div className={`${id  ? 'block  ' : 'hidden  '}` + "video-responsive"}>
                    <iframe width="100%" src={"https://www.youtube.com/embed/" + id + "?autoplay=1&si=g46CIwJi1jKeDA1x"} title="YouTube video player" autoplay="1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                {multimedia.map((n, index) => {
                    return (<div className='w-full  md:w-1/3 md:float-left p-3 hover:p-3 hover:-translate-y-2 transition-all duration-1000 ' key={index}>
                        <div className='rounded-lg bg-white border-2 hover:shadow-2xl'>
                            <img width="400" height="250" alt={n.multimediaName} className='rounded-t-lg  min-w-48 aspect-[16/9]' src={"https://i.ytimg.com/vi/" + n.multimediaCode + "/hqdefault.jpg?sqp=-oaymwE1CKgBEF5IVfKriqkDKAgBFQAAiEIYAXABwAEG8AEB-AH-CYAC0AWKAgwIABABGGUgXihOMA8=&rs=AOn4CLCsD9lHN43WnT0QDYb1GTCUirXKcw"}></img>
                            <div className='w-1/2'></div>

                            <div className="text-left font-bold flex justify-center capitalize">
                                <div className="w-full   bg-green-600 p-4 text-slate-100  text-xs">
                                    <Link className='cursor-pointer' to={`/multimedia/${n.multimediaCode}-${n.multimediaName}#video`} onClick={()=>{
                                         document
                                         .getElementById("video")
                                         ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                    }}>{n.multimediaName.toLowerCase().substr(0, 60)}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                })
                }

            </div>
            < div className='clear-both'></div>
            <Footer />
        </div ></>)
}

export default Multimedia;

