
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faHouse } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom'

const Chronicle = () => {
    const [chronicle, setChronicle] = useState([]);
    const fetchChroniclesData = () => {
        //console.log("Fetch Chronicles");
        fetch("https://clubolesapati.cat/API/apiCroniques.php?top=1&headline=1")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setChronicle(data);
            })
    }

    useEffect(() => {
        fetchChroniclesData();
    }, [])
    return (<div className='w-full bg-slate-800 bg-opacity-30 text-xl ' >
        {chronicle.map((match) => {
            let UpdateDate = match.matchDate.split(" ");
            let d = match.matchDate.split("-");
            let date = d[2] + "-" + d[1] + "-" + d[0];
            //let hour = UpdateDate[1].substring(0, 5);
            match.matchDate = date ;
            match.matchHour =  match.matchHour.substr(0,5);
            return (<div className=" w-full mb-1  bg-opacity-30  border-gray-200  bg-cover hover:bg-center  bg-no-repeat transition-all duration-1000 ease-out" style={{ backgroundImage: "url(https://clubolesapati.cat/images/dynamic/newsImages/" + match.pathImage + ")" }} key={match.matchId}>
                <div className="w-full float-left p-14 text-white-900  text-center   place-content-center ">
                    <div className='w-full inline-block [text-shadow:_0_3px_6px_rgb(0_0_0_/_50%)] '>
                        <div className='w-5/12 float-left bg-s text-right'><span className=" text-xl lg:text-4xl font-bold inline mr-1 align-middle  ">{match.local}</span>
                            <img alt={'Escut de ' + match.local} className='w-4 md:w-10 inline mr-1 lign-bottom' src={match.localImage} />                        </div>
                        <div className='w-2/12 float-left'>
                            <span className='text-center font-bold text-xl lg:text-4xl  align-middle'>{match.localResult}</span> -
                            <span className="text-xl lg:text-4xl  font-bold inline mr-1 align-middle  ">   {match.visitorResult} </span></div>
                        <div className='w-5/12 float-left text-left'> <img className='w-4 md:w-10 inline ml-1' src={match.visitorImage} alt={'Escut de ' + match.visitor} />
                            <span className="ml-1 font-bold inline text-xl lg:text-4xl align-middle">{match.visitor}</span>
                        </div>
                    </div>

                    <div className="text-center text-xl mt-10 [text-shadow:_0_3px_3px_rgb(0_0_0_/_50%)]"><FontAwesomeIcon className='text-base' icon={faHouse} /> {match.complexName} - <FontAwesomeIcon className='text-base' icon={faCalendar} />  {match.matchDate}  {match.matchHour}</div>
                </div>
                <div className="clear-both "></div>
                <div className="text-center font-bold flex justify-center mt-2"><div className="w-60  bg-slate-200 rounded-t-xl p-2 text-slate-800 cursor-pointer "><div className=' duration-1000 transition-all'><Link to={`/noticies/detall/${match.id}-${encodeURIComponent(match.title)}`}>Veure crònica</Link></div></div></div>
            </div>)
        })}</div>)
}

export default Chronicle;