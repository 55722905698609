import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
const NextMatch = (filter) => {
    const [nextMatch, setNextMatch] = useState([]);
    const fetchNextMatchesData = () => {
        fetch("https://clubolesapati.cat/API/apiPropersPartits.php?top=1&" + filter['filter'] + "=" + filter['id'])
            .then(response => {
                return response.json()
            })
            .then(data => {
                setNextMatch(data);
            })
    }
    useEffect(() => {
        fetchNextMatchesData();
    }, [])

    return (
        <>            {
            nextMatch?.map((match, index) => {
                var minutes = match.travelTime / 60;
                var hours = minutes / 60;
                if (hours >= 1) {
                    hours = hours.toString();
                    var tt = hours.substr(0, 3) + " hores";
                } else {
                    var tt = Math.round(minutes) + " minuts";
                }
                if (match.distance == 0) {
                    tt = "";
                    var d = "";
                } else {
                    var d = match.distance + " km - ";
                }
                return (
                    <div className=" w-full bg-slate-100 bg-opacity-100 border-2 border-gray-200 p-8 transition-all" key={match.matchId} >
                        <div className="w-full float-left text-slate-900  place-content-center ">
                            <div className="text-center">
                                <img alt={'Escut de ' + match.local} className='w-6 md:w-14 inline mr-3' src={match.localImage} />  <a target="_blank" rel="noreferrer" href={'https://www.google.com/maps?q=' + match.complexAddress}> <span className="text-sm md:text-xl font-bold inline mr-3">{match.local}</span></a> <span className="inline">vs</span>  <span className=" text-sm md:text-xl ml-2 font-bold inline">{match.visitor}</span> <img alt={'Escut de ' + match.visitor} className='w-6 md:w-14 inline ml-2' src={match.visitorImage} />
                            </div>
                        </div>
                        <div className="w-full  text-slate-900 mt-12 text-base lg:text-xl text-center ">
                            <FontAwesomeIcon icon={faClock} className=" mr-3 " />   {match.matchDate} {match.matchHour}   <br />
                            <FontAwesomeIcon icon={faMapLocationDot} className=" ml-4 mr-3" /><a target="_blank" rel="noreferrer" href={"https://maps.google.com/?q=" + match.complexAddress}>{match.complexName}</a><br />
                            <span className="text-slate-900  text-base font-bold">{d} </span>  <span className="text-slate-900 text-base font-bold">{tt}</span>
                        </div>


                        <div className="clear-both "></div>
                    </div>)

            })
        }</>

    )
}

export default NextMatch;