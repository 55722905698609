import { useState, useEffect } from 'react';
import Marquee from '../../components/00Global/01Marquee';
import Navbar from '../../components/00Global/02NavBar';
import Footer from '../../components/00Global/03Footer';

const Calendar = () => {

    useEffect(() => {
        document.title = 'Calendari · Club Olesa Patí';
    })
    const allMonth = ["Gener", "Febrer", "Març", "Abril", "Maig", "Juny", "Juliol", "Agost", "Setembre", "Octubre", "Novembre", "Desembre"];
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const [calendar, setCalendar] = useState([]);
    const [yearToSee, setYear] = useState(year);
    const [monthToSee, setMonth] = useState(month);
    const handleMonth =(e)=>{
      /*   alert(e);
        console.log(e) */
        setMonth(e+1);
    }
    console.log(day + "-" + month + "-" + year);

    const fetchCalendar = () => {
        fetch("https://clubolesapati.cat/API/apiCalendari.php?month=" + monthToSee + "&year=" + yearToSee)
            .then(response => {
                return response.text()
            })
            .then(data => {
                setCalendar(data);
                //document.getElementById("calendar").innerHTML=data;
            })
    }
    useEffect(() => {
        fetchCalendar();

    }, [monthToSee]);

    return (
        <div className='min-h-screen flex flex-col justify-between'>
            <div>
                <Marquee />
                <Navbar active="competicio" />
            </div>
            <div className='flex justify-between w-full mt-3 px-5 '>
                {allMonth.map(function (month,index) {
                    return <div className={`${index === monthToSee-1 ? 'bg-green-700' : 'bg-gray-300' }` + ' p-3  rounded-xl text-white cursor-pointer'} onClick={()=>{handleMonth(index)}}>{month}</div>;
                })}
            </div>
            <div className='p-3' id="calendar" dangerouslySetInnerHTML={{ __html: calendar }}>
            </div>
            <Footer />
        </div>)
}
export default Calendar;