import { useState, useEffect } from 'react';
import Marquee from '../../components/00Global/01Marquee';
import Navbar from '../../components/00Global/02NavBar';
import Footer from '../../components/00Global/03Footer';
import NextMatch from '../../components/03NextMatch';
import { useParams } from 'react-router-dom'
import "react-toggle/style.css"
import Toggle from 'react-toggle'



const League = (props) => {
    let { id } = useParams();
    let idLeague = id.split("-");
    id = idLeague[0];
    const [classification, setClassification] = useState([]);
    const [matches, setMatches] = useState([]);
    const [allMatches, setAllMatches] = useState(false);

    const handleAllMatches = () => {
        setAllMatches(allMatches => !allMatches);
    }


    const fetchClassificationData = () => {
        fetch("https://clubolesapati.cat/API/apiClassificacionsPerId.php?idLeague=" + id)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setClassification(data);
            })

    }

    const fetchMatchesData = () => {
        fetch("https://clubolesapati.cat/API/apiTotsElsPartits.php?idLeague=" + id)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setMatches(data);

            })
    }
    useEffect(() => {
        fetchClassificationData();

    }, []);
    useEffect(() => {
        fetchMatchesData();

    }, []);

    useEffect(() => {
        let title = "";
        let words = idLeague[1].toLowerCase().split(" ");
        words.forEach(word => {
            word = word.slice(0, 1).toUpperCase() + word.slice(1, word.length);
            console.log(word);
            title = title + " " + word;
        });
        console.log(title)
        document.title = title + ' · Club Olesa Patí';
    }, [])
    return (
        <div className='min-h-screen flex flex-col justify-between'>
            <div >
                <Marquee />
                <Navbar active="competicio" />
            </div>
            <div className="  w-full h-1/4 lg:h-auto  bg-cover bg-center flex justify-center items-center  pt-0 pb-0 ">
                <div className="max-w-7xl mx-auto w-full  text-white text-lg p-4 md:p-2 pt-4 md:pt-10 ">
                    <NextMatch filter='idLeague' id={id} />
                    <div className={`${classification != null ? 'block' : 'hidden'}`}>
                        <h3 className="w-full text-3xl text-green-700 md:pt-12 p-2 pb-3 text-left my-auto  pl-4 md:pl-0"><strong>Classificació</strong></h3>
                        <div className=' w-full  flex'>
                            <div className='w-16 float-left bg-slate-500 p-2  text-slate-900 text-center  border-t border-slate-500 font-bold'>
                                &nbsp;
                            </div>
                            <div className='float-left bg-slate-500 p-2  text-slate-900 text-left border-t border-slate-500 hidden md:block '>
                                &nbsp;
                            </div>
                            <div className='grow float-left bg-slate-500 p-2  text-slate-900 font-bold justify-center border-t border-slate-500  whitespace-nowrap text-sm md:text-xl   '>
                                &nbsp;</div>
                            <div className='w-16 float-left bg-slate-800 p-2  text-slate-100 text-center border-t  border-slate-900 font-bold'>P</div>
                            <div className='w-16 float-left bg-slate-500 p-2 text-slate-900 text-center border-t border-r border-slate-500 hidden md:block text-sm md:text-base'>J</div>
                            <div className='w-16 float-left bg-slate-500 p-2 text-slate-900 text-center border-t border-r border-slate-500 hidden md:block text-sm md:text-base'>G</div>
                            <div className='w-16 float-left bg-slate-500 p-2 text-slate-900 text-center border-t border-r border-slate-500 hidden md:block text-sm md:text-base'>E</div>
                            <div className='w-16 float-left bg-slate-500 p-2 text-slate-900 text-center border-t border-r border-slate-500 hidden md:block text-sm md:text-base'>   P</div>
                        </div>
                
                    {classification?.map((c) => {
                        return (<div key={c.classId} className=' w-auto  flex'>
                            <div className='w-16 float-left bg-slate-500 p-2 md:p-2 text-slate-900 text-center  border-t border-slate-500 font-bold  text-sm md:text-base '>
                                {c.position}
                            </div>
                            <div className='float-left bg-slate-100  text-slate-900 text-left border-t border-slate-500 hidden md:block  text-sm md:text-base '>
                                <img className='w-12 p-2 ' src={c.teamImgSrc} alt='Escut' />
                            </div>
                            <div className='grow float-left bg-slate-100 p-2 pt-4 md:p-2 font-bold text-slate-900 justify-center border-t border-slate-500  whitespace-nowrap text-xs md:text-base   '>
                                {c.teamName}</div>
                            <div className='w-16 float-left bg-slate-800 p-2 md:p-2 text-slate-100 text-center border-t font-bold  text-sm md:text-base '>
                                {c.points}</div>
                            <div className='w-16 float-left bg-slate-100 p-3 md:p-2 text-slate-900 text-center border-t border-r border-slate-500 hidden md:block  text-sm md:text-base '>
                                {c.played}</div>
                            <div className='w-16 float-left bg-slate-100 p-1 md:p-2 text-slate-900 text-center border-t border-r border-slate-500 hidden md:block  text-sm '>{c.won}</div>
                            <div className='w-16 float-left bg-slate-100 p-1 md:p-2 text-slate-900 text-center border-t border-r border-slate-500 hidden md:block  text-sm  '>{c.draw}</div>
                            <div className='w-16 float-left bg-slate-100 p-1 md:p-2 text-slate-900 text-center border-t  border-r border-slate-500 hidden md:block  text-sm md:text-base '>   {c.lost}</div>
                        </div>)
                    })}
                    <div className='w-full border-t border-slate-500 text-slate-500'>&nbsp;</div>    </div>
                </div>
            </div>

            <div className="max-w-7xl mx-auto w-full  text-white text-lg p-4 md:p-2 ">
                <div className='w-2/3 float-left'>
                    <h3 className="w-full text-3xl text-green-700  text-left my-auto  pl-4 md:pl-0 p-2 pb-3 "><strong>Partits</strong></h3>
                </div>
                <div className='w-1/3 float-left text-right text-slate-500 flex justify-end'>
                    <div className='mt-4 text-xs md:text-base'>Veure tots els partits</div>
                    <div className=' mt-4 ml-1'><Toggle
                        checked={allMatches}
                        onChange={handleAllMatches}></Toggle>
                    </div>
                </div>
                <div className='clear-both h-px '>&nbsp;</div>
                {matches?.map((m) => {

                    let d = m.matchDate.split("-");
                    let md = d[2] + "-" + d[1] + "-" + d[0].substring(2, 4);
                    let mh = m.matchHour.substring(0, 5);


                    return (
                        <div key={m.localId + m.visitorId} className={`${allMatches === false && m.localClub != 1 & m.visitorClub != 1 ? 'hidden' : 'block'}`}>
                            <div className={`${m.localResult || m.localClub == 1 || m.visitorClub == 1 ? 'block' : 'hidden'}` + " w-auto flex"}>
                                <div className={`${allMatches && (m.localClub == 1 || m.visitorClub == 1) ? 'bg-green-700 bg-opacity-90' : 'bg-slate-100  text-slate-900'}` + " border-l font-bold w-4/12 flex justify-start items-center md:p-4 border-t border-slate-500 text-[8px] md:text-base transition-all ease-in duration-500"}>
                                    <img className='w-12 p-2 ' src={m.localImage} alt='Escut' /> {m.local}  </div>
                                <div className={`${allMatches && (m.localClub == 1 || m.visitorClub == 1) ? 'bg-green-700 bg-opacity-90' : 'bg-slate-100  text-slate-900'}` + " font-bold w-4/12 flex justify-end items-center md:p-4 text-right border-t border-slate-500 text-[8px] md:text-base transition-all ease-in duration-500 "}>
                                    {m.visitor}   <img className='w-12 p-2 ' src={m.visitorImage} alt='Escut' /></div>
                                <div className='w-1/12 flex justify-center items-center  bg-slate-800  text-slate-100 text-center border-t font-bold text-[8px] md:text-base'>
                                    {m.localResult} - {m.visitorResult}</div>
                                <div className='w-3/12 p-2  justify-start bg-slate-100 text-slate-900 border-t border-r border-slate-500 text-[8px] md:text-base flex items-baseline'>
                                    <div className='w-2/3 font-bold'><a target='_blank' rel="noreferrer" href={'http://maps.google.com/?q='+m.complexAddress} >{m.complexName} </a></div>
                                    <div className='w-1/3 text-right'>{md}<br /> {mh}</div>
                                </div>

                            </div>
                        </div>)
                })}
                <div className='w-full border-t border-slate-500 text-slate-500'>&nbsp;</div>
            </div>

            <Footer />
        </div>

    )
}
export default League;