import react, {useEffect}from 'react'
import Marquee from '../../components/00Global/01Marquee';
import Navbar from '../../components/00Global/02NavBar';
import Header from '../../components/01Home/01Header';
import History from '../../components/01Home/02History';
import NextMatchesLastResults from '../../components/01Home/03NextMatchesLastResults';
import News from '../../components/01Home/05News'
import Footer from '../../components/00Global/03Footer'
import Sponsors from '../../components/01Home/06Sponsors';
const Home = () => {
    useEffect(()=> {
        document.title = 'Club Olesa Patí';
      })
    return (<>

        <div className='absolute z-50'>
            <Marquee />
            <Navbar />
        </div>
        <Header />
        <History />
        <NextMatchesLastResults />
      
        <News />  <Sponsors />
        <Footer />

    </>)
}
export default Home;