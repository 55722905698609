import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const News = () => {

    const [news, setNews] = useState([]);
  
    const fetchUserData = () => {
        fetch("https://clubolesapati.cat/API/apiNoticies.php?top=3&headline=1")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setNews(data);

            })
    }

    useEffect(() => {
        fetchUserData();
    }, []);
    return (<>
        <div className="max-w-7xl mx-auto  text-green-900 text-lg mt-0  ">
            <h3 className="w-full text-4xl text-green-700 pt-12 pb-6 text-center my-auto">Últimes <strong>Notícies</strong></h3>
            {news.map((n, index) => (
                <Link to={`/noticies/detall/${n.id}-${encodeURIComponent(n.title)}`} className='cursor-pointer'>
                <div className='w-full lg:w-1/3 lg:float-left p-3 hover:p-1 hover:-translate-y-2 transition-all duration-500 ' key={index}>
                    <div className='rounded-lg bg-white border-2 hover:shadow-2xl'>
                        <img alt={n.title} className='rounded-t-lg  aspect-[2/1]' src={'https://clubolesapati.cat//images/dynamic/newsImages/' + n.id + '.jpg'}></img>
                        <div className='w-1/2'>
                            <div className='p-4  text-sm'>Fa {n.time} dies</div>
                        </div>
                        <div className='w-1/2'></div>
                        <div className='clear-both font-bold text-sm px-4'>  {n.title}</div>
                        <div className='px-4 py-2 text-sm italic'>{n.subtitle} &nbsp;</div>
                        <div className="text-center font-bold flex justify-center mt-2">
                            <div className="w-60  bg-green-600 rounded-t-xl p-2 text-slate-100 cursor-pointer">
                                <Link to={`/noticies/detall/${n.id}-${encodeURIComponent(n.title)}`}>Veure notícia</Link></div></div>
                    </div>
                </div>
                </Link>
            ))}

        </div>
        <div className='clear-both'></div>
       
    </>
    )
}
export default News;