import { useState, useEffect } from 'react';

import Marquee from '../../components/00Global/01Marquee';
import Navbar from '../../components/00Global/02NavBar';
import Footer from '../../components/00Global/03Footer';
import { Link } from 'react-router-dom';

const News = () => {
    useEffect(() => {
        document.title = 'Notícies · Club Olesa Patí';
    })
    const [news, setNews] = useState([]);
    const fetchNewsData = () => {
        console.log("Fetch");
        fetch("https://clubolesapati.cat/API/apiNoticies.php?top=100&headline=1")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setNews(data);

            })
    }
    useEffect(() => {
        fetchNewsData();
    }, []);
    return (<>
        <div >
            <Marquee />
            <Navbar />
        </div>
        <div className="max-w-7xl mx-auto  text-green-900 text-lg mt-0 p-4 lg:p-0 ">
            <h3 className="w-full text-medium md:text-4xl text-green-700 lg:pt-12 lg:pb-6  my-auto pl-4 "> <strong>Notícies</strong></h3>
            {news?.map((n, index) => {
                let UpdateDate = n.UpdateDate.split(" ");
                let d = UpdateDate[0].split("-");
                let date = d[2] + "-" + d[1] + "-" + d[0].substring(2, 4);
                let hour = UpdateDate[1].substring(0, 5);

                n.UpdateDate = date;
                return (
                    <Link className='cursor-pointer' to={`/noticies/detall/${n.id}-${encodeURIComponent(n.title)}`}>
                        <div className='w-full lg:w-1/3 lg:float-left p-3 hover:py-3 hover:-translate-y-2 transition-all duration-500 ' key={index}>
                            <div className='rounded-lg bg-white border-2 hover:shadow-2xl'>
                                <img alt={n.title} className='rounded-t-lg aspect-[2/1]' src={'https://clubolesapati.cat//images/dynamic/newsImages/mobile/' + n.pathImage}></img>

                                <div className='w-full lg:w-4/4 float-left '>
                                    <div className='clear-both font-bold text-sm px-4 py-2'>  {n.title}</div>
                                </div>
                                <div className='w-full lg:w-1/4 float-left hidden'>
                                    <div className='px-4 py-3  text-sm'>{n.UpdateDate}</div>
                                </div>
                                <div className='clear-both px-4 py-0 text-sm italic'>{n.subtitle} &nbsp;</div>
                                <div className="text-center font-bold flex justify-center mt-8"><div className="w-60  bg-green-600 rounded-t-xl p-2 text-slate-100 ">
                                    <Link className='cursor-pointer' to={`/noticies/detall/${n.id}-${encodeURIComponent(n.title)}`}>Veure notícia</Link>
                                </div>
                                </div>
                            </div>
                        </div>
                    </Link>)
            })}
            <div className='clear-both'></div>
        </div>

        <Footer /></>
    )
}

export default News;