import { useState, useEffect } from 'react';

import Marquee from '../../components/00Global/01Marquee';
import Navbar from '../../components/00Global/02NavBar';
import Footer from '../../components/00Global/03Footer';
import NextMatch from '../../components/03NextMatch';

import { useParams } from 'react-router-dom'

const Team = () => {
    let numberOfPlayers = 0;
    const [teams, setTeams] = useState([]);
    let { id } = useParams();
    let idTeam = id.split("-");
    id = idTeam[0];

    useEffect(() => {
        let title = "";
        let words = idTeam[1].toLowerCase().split(" ");
        words.forEach(word => {
            word = word.slice(0, 1).toUpperCase() + word.slice(1, word.length);
            console.log(word);
            title = title + " " + word;
        });
        console.log(title)
        document.title = title + ' · Club Olesa Patí';
    }, [])


    const fetchUserData = () => {
        fetch("https://clubolesapati.cat/API/apiEquip.php?top=3&headline=1&idTeam=" + id)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setTeams(data);

            })
    }
    numberOfPlayers = teams.length
    useEffect(() => {
        fetchUserData();
    }, []);

    return (<>
        <div className='min-h-screen flex flex-col justify-between'>
            <div>
                <Marquee />
                <Navbar />
            </div>
            <div className=" max-w-7xl mx-auto  text-green-900 text-lg mt-0 p-4 lg:p-0  ">
                <h3 className="w-full text-medium md:text-4xl text-green-700 lg:pt-12   my-auto lg:pl-4 mb-4"> <strong>{idTeam[1]}</strong></h3>
                <NextMatch filter="idTeam" id={id} />
                {//console.log(numberOfPlayers)
                }
                {

                    teams.map((n, index) => {
                        let png = n.image.replace("jpg", "png");
                        return (
                            <div className='w-1/3 lg:w-1/5 float-left px-1 py-2 hover:px-1 hover:-translate-y-1 transition-all duration-500 ' key={index}>
                                <div className='rounded-lg bg-white border-2 hover:shadow-2xl hover:bg-green-600 transition-all duration-1000'>
                                    <div className='playerImages'>
                                        <img alt={n.title} className='playerJpg aspect-[3/4] rounded-t-lg h-100  transition-all duration-1000' src={'https://clubolesapati.cat/images/dynamic/playersImages/' + n.image}></img>
                                        {/*  <img alt={n.title} className='playerPng rounded-t-lg w-full h-[225px] my-[50px] transition-all duration-1000' src={'https://clubolesapati.cat/v2/content/images/header_images/players/' + png}></img>
 */}
                                    </div>
                                    <div className="text-center font-bold flex justify-center  capitalize">
                                        <div className="w-full  bg-green-600 r p-2 text-slate-100 text-xs md:text-base">
                                            {n.playerName.toLowerCase()}
                                        </div>
                                    </div>
                                </div>



                            </div>

                        )
                    })}

            </div>
            <div className='clear-both'></div>
            <Footer />
        </div >
    </>
    )
}

export default Team;